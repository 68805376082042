import React from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { categoryData, searchHandler, navigateOnFiltering } = props;
  const itemKey = uuidv4();

  return (
    <>
      <li key={itemKey} className="w-11/12 h-10 px-0 py-0 align-middle list-none border-b border-blue-200 last:border-b-0">
        <div className="inline-block w-10/12 text-left">
          {navigateOnFiltering ? (
            <Link to="/blog" state={{ searchParams: { searchContext: "category-search", searchTerm: categoryData.category } }}>
              <p className="text=gray-500 focus:outline-none hover:text-gray-500 hover:underline">{categoryData.category}</p>
            </Link>
          ) : (
            <button className="text=gray-500 hover:text-gray-500 hover:underline" onClick={() => searchHandler({ searchContext: "category-search", searchTerm: categoryData.category })}>
              <p className="text=gray-500 focus:outline-none hover:text-gray-500 hover:underline">{categoryData.category}</p>
            </button>
          )}
        </div>
        <div className="inline-block w-2/12 text-right ">{/* <p className="text-blue-500 focus:outline-none">{`(${categoryData.numberOfPosts})`}</p> */}</div>
      </li>
    </>
  );
};
