import React from "react";
import { FolderOpenOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { articleData, showReadMoreLink } = props;
  const itemKey = uuidv4();

  const smallViewClassName = "px-0 py-2 mx-0 border-b border-blue-200 lg:py-4 lg:mx-2 last:border-b-0";
  const largeViewClassName =
    "pl-4 pr-4 py-2 mx-0 text-gray-500 border border-blue-200 border-l-0 border-t-0 border-r-0 hover:shadow-lg hover:text-gray-500 hover:cursor-pointer hover:bg-blue-100 hover:border hover:border-gray-100 hover:rounded lg:py-4 lg:mx-2 last:border-b-0";
  const className = showReadMoreLink ? smallViewClassName : largeViewClassName;

  return (
    <>
      <Link to={articleData.slug}>
        <li key={itemKey} className={className}>
          <h2 className="text-gray-500 lg:ml-1">{articleData.blogTitle}</h2>
          <div className="px-2 py-1 text-blue-500 ">
            <span className=""></span>
            <span className="ml-0">
              <FolderOpenOutlined className="px-1 py-1 align-top" />
              {articleData.blogCategory}
            </span>
          </div>
          <p className={`px-2 ${!showReadMoreLink ? "pb-3 " : " "}`}>{articleData.blogSummary}</p>
        </li>
      </Link>
      {showReadMoreLink && (
        <div className="px-2 py-4 lg:pt-4 lg:pb-0">
          <Link to={articleData.slug} className="text-blue-500 hover:text-gray-500 hover:underline">
            Read more <ArrowRightOutlined className="px-1 py-1 align-top" />
          </Link>
        </div>
      )}
    </>
  );
};
