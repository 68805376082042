import React from "react";
import BlogIndex from "../components/BlogIndex";
import Layout from "../components/Layout";

export default () => {
  return (
    <Layout
      pageTitle="The Comparison Company Blog"
      pageDescription="The number one resource for honest and independent reviews of suppliers and products. We tell you what you need to know to make life easy."
    >
      <BlogIndex />
    </Layout>
  );
};
